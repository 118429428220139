import React from "react";

import productJSON from '../../Data/products.json';

import './ProductTitle.css';
import bannerImage from '../../assets/images/background.png'


const ProductTitle = ({ selectedProduct, selectedVersion }) => {

    const cleanVersion = (v) => {
        return v.replace(/([0-9]+).([0-9]+).0$/g,'$1.$2');
    }

    return(
        <section id="product-title-section" style={{backgroundImage:`url(${bannerImage})`}}>
            <h1 id="product-title">
                {selectedProduct in productJSON ? `${productJSON[selectedProduct].title_long}` : ''}
            </h1>
            <h3>
                {selectedVersion && selectedVersion !== "None" ? `Version ${cleanVersion(selectedVersion)}` : ''}
            </h3>
        </section>
    )
}

export default ProductTitle;