import React from "react";
import './TopicCard.css';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

// import productJSON from '../../Data/products.json';
// import categoriesJSON from '../../Data/topic-categories.json';

const TopicCard = ({ topicObj, parentProduct }) => {

    // const getCategories = () => {
    //     const categories = [];
    //     const content = parentProduct in productJSON ? productJSON[parentProduct]['content'] : {};

    //     Object.keys(content).forEach(categoryId => {
    //         const topicList = content[categoryId];
    //         if(topicList.includes(topicObj.id)){
    //             if(!categoriesJSON[categoryId].key.startsWith('all')){
    //                 categories.push(
    //                     <div 
    //                         key={`topic-category-${categoriesJSON[categoryId].key}`}
    //                         className="topic-card-tags-item"
    //                     >
    //                         {categoriesJSON[categoryId].title}
    //                     </div>
    //                 )
    //             }
    //         }
    //     });

    //     return categories;
    // };

    // const sanitizedTitle = DOMPurify.sanitize(topicObj.title);
    const sanitizedTitle = DOMPurify.sanitize(topicObj.title);

    return (
        <a 
            href={topicObj.link} 
            target={topicObj.link.startsWith('#') ? "_self" : "_blank" }
            rel={topicObj.link.startsWith('#') ? undefined : "noopener noreferrer"}
            className="topic-card-link" 
            title={`Explore ${parse(sanitizedTitle)}`}
        >
            <div className="topic-card" tabIndex="1" id="1">
                <h4 className="topic-card-title">{parse(sanitizedTitle)}</h4>
                <div className="topic-card-footer">
                    <div className="topic-card-tags">
                        {/* {
                            [...getCategories()]
                        } */}
                        {
                            topicObj.tags.map(tagName => <div key={`topic-tag-${tagName}`} className="topic-card-tags-item">{tagName}</div>)
                        }
                    </div>
                </div>
            </div>
        </a>
    )
}

export default TopicCard;
